export const countries = [
    // {
    //     name: "Côte d'Ivoire",
    //     code: "CI",
    //     dialCode: "+255",
    //     flag: require("../assets/flags/ci.png")
    // },
    // {
    //     name: "France",
    //     code: "FR",
    //     dialCode: "+33",
    //     flag: require("../assets/flags/fr.png")
    // },
    {
        name: "Russie",
        code: "RU",
        dialCode: "+7",
        flag: require("../assets/flags/ru.png")
    }
];